.navigation {
  max-width: 250px;
  position: absolute;
  left: 20px;
  z-index: 3;
}

.header .navigation {
  top: 24px;
}

.navigation_list {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.navigation_list-item {
  margin: 0 22px 0 0;
}

.navigation_link {
  text-decoration: none;
  color: #ffffff;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.02em;
  transition: color 0.5s ease 0.1s;
}

.navigation_link:hover {
  color: #846f5e;
}
@media screen and (orientation: landscape) and (max-width: 700px) {
  .navigation_list {
    display: flex;
    flex-direction: row;
  }
}
@media screen and (orientation: portrait) and (max-width: 700px) {
  .navigation_list {
    display: flex;
    flex-direction: row;
  }
  .navigation {
    top: 16px;
    left: 16px;
  }
}
