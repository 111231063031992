.profession {
  margin: 0 0 15px 18px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.22;
  letter-spacing: -0.02em;
  z-index: 1;
}

.profession_main-page {
  margin: 0 0 20px 0;
  font-size: 45px;
}

@media screen and (max-width: 1080px) {
  .profession_main-page {
    font-size: 32px;
  }
}

@media screen and (max-width: 700px) {
  .profession_main-page {
    font-size: 14px;
  }
}
