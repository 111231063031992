.about {
  max-width: 1280px;
  margin: auto;
  padding: 33px 0;
  display: flex;
  letter-spacing: -0.02em;
  position: relative;
}

.about__column {
  margin: auto;
}

.about__title {
  margin: 0 0 40px;
  font-style: normal;
  font-weight: 900;
  font-size: 25px;
  line-height: 1.2;
  letter-spacing: -0.02em;
}

.about__text {
  max-width: 400px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.21;
  letter-spacing: -0.02em;
}

.about__list {
  margin-top: 10px;
  padding: 0 10px;
  list-style-type: " - ";
}

.about__list-item {
  margin: 0 0 10px 0;
}

@media screen and (max-width: 500px) {
  .about {
    padding: 28px 14px;
  }
  .about__title {
    font-size: 20px;
  }

  .about__text {
    font-size: 13px;
  }
}
