.design {
  color: #ffffff;
  margin: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.02em;
  text-decoration-line: underline;
  transition: color 0.5s ease 0.1s;
}
.design:hover {
  color: #846f5e;
  cursor: pointer;
}

@media screen and (max-width: 700px) {
  .design {
    font-size: 12px;
  }
}

@media screen and (orientation: portrait) and (max-width: 500px) {
  .design {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    bottom: 26px;
    left: 0;
    right: 0;
    text-align: center;
  }
}
