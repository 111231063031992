.main {
  margin-bottom: 32px;
  max-width: 1280px;
  padding: 22px 120px;
  display: flex;
  justify-content: space-between;
  letter-spacing: -0.02em;
  background: rgba(0, 0, 0, 0.7);
}

.main__title {
  margin: 0;
  font-style: normal;
  font-weight: bold;
  font-size: 70px;
  line-height: 1.21;
  letter-spacing: -0.02em;
}

.main__design {
  color: #ffffff;
  margin: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.02em;
  text-decoration-line: underline;
  transition: color 0.5s ease 0.1s;
}
.main__design:hover {
  color: #846f5e;
  cursor: pointer;
}

.main__column {
  text-align: left;
}

.main__navigation {
  text-align: right;
  padding-top: 20px;
}

.main__navigation_list {
  list-style: none;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.main__navigation_link {
  text-decoration: none;
  color: #ffffff;
  transition: color 0.5s ease 0.1s;
}

.main__navigation_list-item {
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 1.2;
  margin-bottom: 12px;
}

.main__navigation_link:hover {
  text-decoration: underline;
  color: #846f5e;
  cursor: pointer;
}

@media screen and (min-width: 1500px) {
  .main {
    min-width: 1200px;
  }
}

@media screen and (max-width: 1080px) {
  .main__title {
    font-size: 50px;
  }
  .main__subtitle {
    font-size: 32px;
  }
  .main__navigation {
    padding-top: 15px;
  }
}

@media screen and (max-width: 850px) {
  .main {
    padding: 22px 40px;
  }
}

@media screen and (max-width: 700px) {
  .main {
    margin-bottom: 0px;
    padding: 60px 15px;
  }
  .main__title {
    font-size: 30px;
    line-height: 1.23;
  }
  .main__subtitle {
    font-size: 14px;
  }
  .main__design {
    font-size: 12px;
  }

  .main__navigation {
    padding-top: 0;
  }

  .main__navigation_list-item {
    font-size: 20px;
  }
}

@media screen and (max-width: 500px) {
  .main {
    background-color: #050602;
  }
  .main__navigation_list-item {
    font-size: 14px;
  }
}

@media screen and (orientation: portrait) and (max-width: 500px) {
  .main {
    display: grid;
    grid-template-columns: 2fr;
    grid-template-rows: 2fr;
    grid-column-gap: 40px;
    grid-row-gap: auto;
    object-fit: cover;
    max-width: 100%;
    z-index: 0;
  }

  .main__navigation {
    grid-area: 1/-1;
  }
}
