.contacts {
  max-width: 1280px;
  min-height: 60vh;
  margin: auto;
  padding: 33px 0;
  display: flex;
  letter-spacing: -0.02em;
  position: relative;
}

.contacts__column {
  margin: 0 auto;
}

.contacts__title {
  margin: 0 0 40px;
  font-style: normal;
  font-weight: 900;
  font-size: 25px;
  line-height: 1.2;
  letter-spacing: -0.02em;
}

.contacts__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.contacts__list-item {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 1.22;
  letter-spacing: -0.02em;
  display: flex;
  align-items: center;
  margin: 0 0 24px;
}

.contacts__list-item_img {
  margin-right: 24px;
  transition: filter 0.5s ease 0.1s;
}

.contacts__list-item:hover .contacts__list-item_img {
  filter: invert(44%) sepia(16%) saturate(585%) hue-rotate(345deg)
    brightness(98%) contrast(88%);
  /* filter color codepen https://codepen.io/sosuke/pen/Pjoqqp */
}

.contacts__list-item_link {
  display: flex;
  align-items: center;
  color: #ffffff;
  text-decoration: none;
  transition: color 0.5s ease 0.1s;
}

.contacts__list-item_link:hover {
  color: #846f5e;
}

@media screen and (max-width: 700px) {
  .contacts {
    margin-left: 14px;
    margin-right: 14px;
  }
  .contacts__title {
    font-size: 20px;
  }

  .contacts__list-item_img {
    width: 20px;
    height: 20px;
  }

  .contacts__list-item_link {
    font-size: 13px;
  }
}
@media screen and (max-width: 600px) {
  .contacts__column {
    max-width: 300px;
  }
}
@media screen and (max-width: 450px) {
  .contacts {
    max-width: 250px;
  }
  .contacts__list {
    -webkit-mask-image: linear-gradient(to right, black 50%, transparent 100%);
    mask-image: linear-gradient(to right, black 50%, transparent 100%);
  }
}

@media screen and (max-width: 360px) {
  .contacts__column {
    max-width: 200px;
  }
}
