.name {
  margin: 18px 0 6px 18px;
  font-size: 24px;
  line-height: 1.21;
  font-style: normal;
  font-weight: bold;
  letter-spacing: -0.02em;
  z-index: 1;
}

.name_main-page {
  margin: 0;
  font-size: 70px;
}

@media screen and (min-width: 1900px) {
  .name_main-page {
    font-size: 60px;
  }
}

@media screen and (max-width: 1080px) {
  .name_main-page {
    font-size: 50px;
  }
}

@media screen and (max-width: 700px) {
  .name_main-page {
    font-size: 30px;
    line-height: 1.23;
  }
}
