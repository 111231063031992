.App {
  font-family: "Montserrat", "Arial", sans-serif;
  font-weight: normal;
  font-style: normal;
  letter-spacing: 0.05em;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  margin: 0 auto;

  width: 100%;
  background: url("../../images/pic0-min.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  color: #ffffff;
  justify-content: flex-end;
}

.app__wrapper {
  width: 100%;
  z-index: 1;
  background-color: #050602;
}
@media screen and (min-width: 1500px) {
  .App {
    align-items: center;
  }
}
@media screen and (max-width: 500px) {
  .App {
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    background-color: #050602;
    background-position: top center;
    align-items: center;
  }
}

@media screen and (orientation: landscape) and (min-width: 950px) {
  .App {
    background-position: center -150px;
  }
}

@media screen and (orientation: portrait) and (max-width: 500px) {
  .App {
    max-height: 30vh;
    display: grid;
    grid-template-columns: 1fr;
    margin: auto;
    position: relative;
  }
}
