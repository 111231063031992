.header {
  object-fit: cover;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 308px;
  justify-content: flex-end;
}

.header__bg {
  position: absolute;
  z-index: 0;
  width: 100%;
  height: auto;
}

.header__bg_invisible {
  display: none;
}

@media screen and (min-width: 1900px) {
  .header {
    left: auto;
    text-align: center;
  }
  .header__title {
    font-size: 60px;
  }
  .header__subtitle {
    font-size: 35px;
  }
}

@media screen and (orientation: portrait) {
  .header {
    min-height: calc(100vw / 1.51);
  }
}
