.scanner {
    max-width: 1280px;
    margin: auto;
    padding: 33px 0;
    display: flex;
    letter-spacing: -0.02em;
    position: relative;
  }
  
  .scanner__column {
    margin: auto;
    display: flex;
    flex-direction: column;
  }
  
  .scanner__title {
    margin: 0 0 40px;
    font-style: normal;
    font-weight: 900;
    font-size: 25px;
    line-height: 1.2;
    letter-spacing: -0.02em;
  }
  
  .scanner__text {
    max-width: 400px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 1.21;
    letter-spacing: -0.02em;
  }
  .scanner_img{
    box-sizing: border-box;
    max-width: 190px;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    /* outline-width: 0px;
    transition: outline 0.5s ease 0.1s; */
  }
  .scanner__list {
    margin-top: 10px;
    padding: 0 10px;
    list-style-type: " - ";
  }
  
  .scanner__list-item {
    margin: 0 0 10px 0;
  }
  
  @media screen and (max-width: 500px) {
    .scanner {
      padding: 28px 14px;
    }
    .scanner__title {
      font-size: 20px;
    }
  
    .scanner__text {
      font-size: 13px;
    }
  }
  