.work {
  max-width: 1280px;
  margin: auto;
  padding: 33px 0 175px;
  display: flex;
  letter-spacing: -0.02em;
  position: relative;
}

.work__column {
  margin: auto;
  max-width: 80%;
}

.work__title {
  margin: 0 0 40px;
  font-style: normal;
  font-weight: 900;
  font-size: 25px;
  line-height: 1.2;
  letter-spacing: -0.02em;
}

.work__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: grid;
  justify-items: center;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.work__list-item {
  width: 80%;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 1.22;
  letter-spacing: -0.02em;
  /* display: flex; */
  /* margin: 0 0 54px; */
  padding: 10px;
}

.work__list-item:hover {
  cursor: pointer;
}

.work__list-item:hover .work__list-image {
  outline: 1px solid #846f5e;
}

.work__list-item:hover .work__list-item_title {
  color: #846f5e;
}

.work__list-item:hover .work__list-item_link {
  color: #846f5e;
}

.work__list-item:last-of-type {
  margin-bottom: 59px;
}

.work__list-item_title {
  margin: 0 0 10px 0;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.22;
  letter-spacing: -0.02em;
  transition: color 0.5s ease 0s;
}

.work__list-item_link {
  display: flex;
  flex-direction: column;
  color: #ffffff;
  text-decoration: none;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 1.25;
  letter-spacing: -0.02em;
  transition: color 0.5s ease 0.1s;
}

.work__list-image {
  margin: 0 0 18px 0;
  width: 100%;
  height: 130px;
  outline-width: 0px;
  transition: outline 0.9s ease 0.1s, background-position ease-in-out 5s;
  background-size: cover;
  background-position: top;
}

.work__list-image_movies{
  background-image: url(../../images/sites-screenshots/movies-min.png);
}

.work__list-image_how{
  background-image: url(../../images/sites-screenshots/howtolearn-min.png);
}

.work__list-image_mesto{
  background-image: url(../../images/sites-screenshots/mesto-min.png);
}

.work__list-image_travel{
  background-image: url(../../images/sites-screenshots/russian-travel-min.png);
}

.work__list-image_random{
  background-image: url(../../images/sites-screenshots/random-min.png);
}

.work__list-image_notes{
  background-image: url(../../images/sites-screenshots/notes-min.png);
}

.work__list-image_player{
  background-image: url(../../images/sites-screenshots/audioplayer-min.png);
}

.work__list-image_library{
  background-image: url(../../images/sites-screenshots/library-min.png);
}

.work__list-image_tic{
  background-image: url(../../images/sites-screenshots/tictactoe-min.png);
}

.work__list-image_coffee{
  background-image: url(../../images/sites-screenshots/coffee-house-min.png);
}

.work__list-image_hangman{
  background-image: url(../../images/sites-screenshots/hangman-min.png);
  background-position: center calc(50% + 60px);
}

.work__list-image_nonogram{
  background-image: url(../../images/sites-screenshots/nonograms-min.png);
  background-position: center calc(50% + 50px);
}

.work__list-item:hover .work__list-image{
  background-position: bottom;
}

.work__text {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 1.25;
  text-align: center;
  width: 100%;
}

.work__link {
  color: #ffffff;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.25;
  transition: color 0.5s ease 0.1s;
}

.work__link:hover {
  color: #846f5e;
}

@media screen and (max-width: 1150px) {
  .work__column {
    max-width: 50%;
  }
  .work__list {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 650px) {
  .work__title {
    font-size: 20px;
  }
  .work__list-image_hangman{
    background-position: center calc(5%);
  }
  .work__list-image_nonogram{
    background-position: center;
  }
}
