.certificates {
  max-width: 1280px;
  margin: auto;
  padding: 33px 0 175px;
  display: flex;
  letter-spacing: -0.02em;
  position: relative;
}

.certificates__column {
  margin: auto;
  max-width: 80%;
  min-width: 50%;
}

.certificates__title{
  margin: 0 0 40px;
  font-style: normal;
  font-weight: 900;
  font-size: 25px;
  line-height: 1.2;
  letter-spacing: -0.02em;
}

.certificates__list{
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: grid;
  justify-items: center;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.certificates__list-item{
  width: 100%;
}

.certificates__list-item_link{
  color: #ffffff;
  text-decoration: none;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 1.25;
  letter-spacing: -0.02em;
  transition: color 0.5s ease 0.1s;
}

.certificates__list-item_image{
  min-height: 150px;
  max-width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  margin-bottom: 20px;
}

.certificates__list-item_title{
  transition: color 0.9s ease 0.1s;
  font-size: 1.5em;
}

.certificates__list-item_image-yandex{
  background-image: url(../../images/certs/yandexdiploma.png);
}

.certificates__list-item_image-rs0{
  background-image: url(../../images/certs/rsdiploma.png);
}

.certificates__list-item:hover .certificates__list-item_title{
  color: #846f5e;
}

@media screen and (max-width: 750px) {
  .certificates__list{
    grid-template-columns: 1fr;

  }
  .certificates__list-item{
    text-align: center;
  }
  .certificates__list-item_image{
    background-size: cover;
  }
}